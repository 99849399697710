<template>
  <div class="calendar-gs">
    <Calendar />
  </div>
</template>

<script>
import Calendar from '@/views/Calendar';
import axios from 'axios';
import { mapMutations } from 'vuex';

export default {
  name: 'CalendarGS',
  components: {
    Calendar,
  },
  methods: {
    ...mapMutations('loginGeneral', ['setUser']),
  },
  created() {
    if (this.$route.query.token) {
      axios.defaults.headers.common['Authorization'] = this.$route.query.token;
      this.setUser({
        token: this.$route.query.token,
        premiumAccount: true,
      });
    }
  },
};
</script>

<style scoped>
.calendar-gs {
  width: 100%;
}
</style>
<style>
html,
body {
  background-color: rgba(0, 0, 0, 0);
}
</style>
