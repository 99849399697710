<template>
  <div>
    <div class="calendar-container">
      <div class="calendar-container__calendar-view">
        <Calendar
          type="onlycomponent"
          :predictions="predictions"
          :await-for-seasons="false"
          is-full
          @tournament-selected="onTournamentSelected"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from '@/components/Sections/Calendar/CalendarComponent';
import { mapMutations } from 'vuex';

export default {
  name: 'CalendarView',
  props: {
    predictions: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Calendar,
  },
  methods: {
    ...mapMutations('general', ['setTournamentSelected']),
    onTournamentSelected(tournament) {
      this.setTournamentSelected(tournament);
    },
  },
};
</script>

<style lang="scss">
html > body {
  padding-top: 0 !important;
}
.is-fullheight-with-navbar-and-footer,
.is-fullheight-with-navbar-and-footer-mobile {
  height: calc(100% - 1px);
}
.calendar-container {
  display: flex;
  justify-content: center;
  &__calendar-view {
    width: 940px;
    height: 600px;
    table-layout: fixed;
  }
}
</style>
